/*
* CSS For Help Scout Support Site
* Copyright 2013, Exordo
* www.exordo.com
* May 2015
* Author:  Raul Arribas;
* Email: raul@exordo.com
*/

.navbar .navbar-inner{
  background: #f0f0f0; 
}
body,
#docsSearch{
  background: #f0f0f0;
}
h1 {
  font-family: "Droid Serif";
  font-weight: bold;
  font-style: italic;
  text-align: center;
  color: #505050;
  font-size: 30px;
}
h2{
  font-family: "Droid Serif";
  font-style: italic;
  font-weight: bold;
  color: #505050;
  text-align: center;
  font-size: 27px;
  line-height: 28px;
}
h3{
  font-family:'Droid Serif';
  color: #505050;
}
.navbar .nav li a{
  font-family: "Droid Serif";
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
  text-decoration: none;
}
.navbar .nav li a:hover,
.navbar .nav .active a, 
.navbar .nav .active a:hover, 
.navbar .nav .active a:focus{
  color: #94c13d;
}

.category-list .category .article-count{
  font-family:'Droid Serif';
  color: #94c13d;
}
#searchBar button {
  background: #94c13d;
  border: 1px solid #94c13d;
}
#searchBar button:hover{
  color: #505050;
  background: #94c13d;
  border: 1px solid #94c13d;
}
.category-list .category {
  background: white;
  width: 29%;
  border: 1px solid #e0e0e0;
 
}
.twoCol .collection, 
.threeCol .collection,
.contentWrapper{
   -webkit-border-radius: 20px;
          border-radius: 20px;
  box-shadow: 0px 3px 4px 0px #C7C6C6;
}
#sidebar .nav-list a{
  font-family: "Droid Serif";
  font-size: 14px;
  color: #666;
}
#serp-dd 
.result a:hover, 
#serp-dd .result > li.active, 
#full-Article strong a, 
.collection a, 
.contentWrapper a, 
.most-pop-articles 
.popArticles a, 
.most-pop-articles 
.popArticles a:hover span, 
.category-list .category .article-count, 
.category-list .category:hover .article-count {
  color: #94c13d;
}
textarea:focus, 
input:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=datetime]:focus, 
input[type=datetime-local]:focus, 
input[type=date]:focus, 
input[type=month]:focus, 
input[type=time]:focus, 
input[type=week]:focus, 
input[type=number]:focus, 
input[type=email]:focus, 
input[type=url]:focus, 
input[type=search]:focus, 
input[type=tel]:focus, 
input[type=color]:focus, 
.uneditable-input:focus { 
  border-color: #94c13d;
}